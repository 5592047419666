import React from 'react'
import { XNavy } from 'src/components/svgs'
import { Image } from 'src/components/image'
import { PageLink } from 'src/components/link'
import { StandardText } from 'src/components/global/standardText'
import { RevealOnMount } from 'src/utils/revealOnMount'

const Team = ({ pageContext, location, preview = false }: any) => {
  const {
    image,
    teamMembersLocation,
    _rawSpacTeamBioDescription,
    firstName,
    lastName,
    _rawSpacPositionMarkDown,
  } = pageContext

  return (
    <RevealOnMount location={location}>
      <div style={{ paddingTop: preview ? '8rem' : '' }} className="team-section">
        <div className="__breadcrumb flex ">
          <div className="flex align-items-center">
            <PageLink
              activeClass="null"
              previousLink={location.pathname}
              to={`/spac`}
              className="text-decoration-underline blue  neue-pro-55-text-links  "
            >
              SPAC
            </PageLink>
            <span className="margin-left-1rem neue-pro-55-text-links">{`>`}</span>
            <span className="margin-left-1rem  neue-pro-55-text-links">{`${firstName} ${lastName}`}</span>
          </div>
          <PageLink previousLink={location.pathname} activeClass="null" to={`/spac`}>
            <XNavy onClick={() => {}} className="__svg-exit" color="#0b1e31" />
          </PageLink>
        </div>
        <div className="__grid ">
          <div className="__information">
            <div className="---info">
              <h1 className="green margin-bottom-3rem optima-headline-pt2">{`${firstName} ${lastName}`}</h1>
              <div className="__roles">
                {_rawSpacPositionMarkDown && (
                  <StandardText
                    className="neue-pro-55-text-links margin-right-2rem "
                    data={{ text: _rawSpacPositionMarkDown }}
                  />
                )}
                {teamMembersLocation && (
                  <p className=" neue-pro-55-text-links">
                    {teamMembersLocation && teamMembersLocation.title}
                  </p>
                )}
              </div>
            </div>
            <div className="--description">
              <StandardText
                className="neue-pro-55-text-links"
                data={{ text: _rawSpacTeamBioDescription }}
              />
            </div>
          </div>
          <div className="__image-container">
            <Image
              style={{
                height: '100%',
                objectFit: 'cover',
              }}
              className="__profle-image"
              objectFit="cover"
              height={1800}
              loading="eager"
              src={image.asset.url}
              imageId={image.asset.id}
            />
          </div>
        </div>
        <MobileTeam preview={true} location={location} data={pageContext} />
      </div>
    </RevealOnMount>
  )
}

export default Team

const MobileTeam = ({ data, location, preview }: any) => {
  const {
    title,
    firstName,
    lastName,
    image,
    teamMembersLocation,
    teamMembersRoleRef,
    _rawSpacTeamBioDescription,
  } = data
  return (
    <div className="mobile-team-section">
      <div className="__mobile-bread-crumb">
        <div className="flex align-items-center">
          <PageLink
            previousLink={location.pathname}
            activeClass="null"
            className="neue-pro-55-text blue text-decoration-none"
            to="/spac"
          >
            SPAC{' '}
          </PageLink>

          <p className="margin-right-1rem margin-left-1rem neue-pro-55-text ">{'>'}</p>
          <p className="neue-pro-55-text ">{`${firstName + ' ' + lastName}`}</p>
        </div>
        <PageLink
          previousLink={location.pathname}
          activeClass="null"
          className="__exit-button-team"
          to="/spac"
        >
          <XNavy onClick={() => null} className="__svg-exit " />
        </PageLink>
      </div>
      <h1 style={{ fontSize: '2.5rem' }} className=" __title green  optima-headline-pt2">
        {title}
      </h1>
      <div className="image-full-section">
        <Image
          style={{
            height: '100%',
            objectFit: 'cover',
            objectPosition: preview ? '69% 25%' : '',
          }}
          className="__image-section x y "
          objectFit="cover"
          src={image.asset.url}
          imageId={image.asset.id}
        />
      </div>
      <div className="__info padding-box">
        <div className="__labels">
          <p className="neue-pro-55-text">{teamMembersLocation && teamMembersLocation.title}</p>
          <p className="neue-pro-55-text">{teamMembersLocation && teamMembersRoleRef.title}</p>
        </div>

        <div className="__description">
          <StandardText
            className="neue-pro-55-text-links"
            data={{
              text: _rawSpacTeamBioDescription || data.spacTeamBioDescription,
            }}
          />
        </div>
      </div>
    </div>
  )
}
